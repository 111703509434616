import { makeAutoObservable } from "mobx"

type Answers = {
    key: number,
    answer: number,
    textanswer?: string,
} 

class HumorStore {
    answers = [{
        key: 22,
        answer: 0,
        textanswer: ""
    }] as Answers[]
    questions = [
        "Я эксперт по мемам",
        "Я часто общаюсь при помощи мемов",
        "Я сам рисую (генерирую) мемы.",
        "Я знаю в какой ситуации будет уместно использовать тот или иной мем, шутку",
        "Я люблю мемы",
        "У меня хорошее чувство юмора",
        "До меня долго доходят шутки",
        "Я разбираюсь в юморе",
        "Есть вещи, о которых шутить нельзя",
        "Я многое могу сказать о человеке по его шуткам",
        "У моих друзей плохо с чувством юмора",
        "Юмор помогает мне в моей жизни",
        "Я редко шучу",
        "Я часто не понимаю шутки, мемы",
        "«Я думал, что моя жизнь трагедия, оказалось, что комедия»",
        "Я часто обижаюсь на шутки в мой адрес",
        "Я умею троллить",
        "Я легко могу определить, будет ли человеку смешно",
        "Я осторожен с шутками, не хотел бы обидеть или попасть впросак",
        "Люди смеются над моими шутками"
    ]

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })

        for (let i = 1; i < 21; i++ ) {
            const newAnswer = {
                key: i,
                answer: 0
            }

            this.answers.push(newAnswer)
        }
    }

    addNewAnswer(questionNumber: number, answer: number){
        //console.log(questionNumber, answer)
        this.answers[questionNumber + 1].answer = answer;
        //console.log(this.answers[questionNumber])
    }

    addTextAnswer(text : string){
        this.answers[0].textanswer = text
        //console.log(this.answers)
    }
}

export default HumorStore