const expertsGroup = {
    title: "Экспертиза мемов",
    instruction: "Мы изучаем современное чувство юмора и хотим понять, что делает мемы из социальных сетей смешными и для кого. Мы обращаемся к вам как к человеку с хорошим чувством юмора, чтобы вы помогли нам отобрать мемы для нашего будущего исследования. Для этого вам нужно будет отметить 10 мемов из 30 предложенных, которые на ваш взгляд являются наиболее смешным и прокомментировать свой выбор. Мы еще зададим несколько вопросов о месте юмора в вашей жизни. Мы бы также хотели услышать ваши комментарии и по поводу формулировок этих вопросов будущего опросника. Вся процедура займет около 30 минут. Спасибо.",
}

const controlGroup = {
    title: "Исследование чувства юмора",
    instruction: "Мы изучаем современное чувство юмора и хотим понять, что делает мемы из социальных сетей смешными и для кого. Мы хотим узнать, что смешно для вас. Мы предложим вам 30 мемов, из которых вам нужно будет отобрать 10 самых смешных и пояснить свой выбор. А до этого мы зададим несколько вопросов о месте юмора в вашей жизни. Вся процедура займет около 30 минут. Спасибо за участие. ",
}

export {
    expertsGroup,
    controlGroup
}