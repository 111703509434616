import QuestionCard from "../../components/second-step/question-card/question-card"
import MainStore from "../../store/main-store";

type propsMemesQuality = {
    store: MainStore
}

export default function MemesQuality({store}: propsMemesQuality) {
    return (
        <>
            <div className="memes-choose-table">
                {
                    store.getChoosedMemes().map((memes) => {
                        //console.log(memes + "memes1")
                        return (
                            <QuestionCard store={store} image={memes.image} keys={memes.key} />
                        )
                    })
                }
            </div>
            <a href="/humor-in-my-life" className="submit-button">
                 Далее
            </a>
        </>
    )
}