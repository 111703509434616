import { observer } from "mobx-react-lite"
import { useState } from "react"

import "./radio.css"

type radioProps = {
    text: string,
    keys: number,
    questionNumber: number,
    value: number,
    onEdit: Function,
    min: number,
    max: number
}

const Radio = observer( (props: radioProps) => {
    const [value, setValue] = useState(props.value)
        
    return (
        <div>
            <p className="question">
                {props.text}
            </p>
            <p className="value">
                {value}
            </p>
            <div className="scale">
                <span>
                    <p>{props.min}</p>
                </span>
                <div className="slidecontainer">
                    
                    <input
                        onChange={(e) => {
                            props.onEdit(props.keys - 1, props.questionNumber, Number(e.target.value))
                            setValue(Number(e.target.value))
                        }}
                        type="range"
                        min={props.min}
                        max={props.max}
                        value={props.value}
                        className="slider"
                        id="myRange"
                    />
                </div>

                <span>
                    <p>{props.max}</p>
                </span>
            </div>
            <div className="line"></div>
        </div>
    )
})

export default Radio