import StartComponent from "../components/start/start";
import MainStore from "../store/main-store";

type propsFirstGroup = {
    store: MainStore
}


export default function TolokaSecond ({store}: propsFirstGroup) {
    return (
        <div>
            <StartComponent groupType="control" store={store} toloka={true}/>
        </div>
    )
}