import axios from "axios"
import { useEffect, useState } from "react"
import "./result.css"

export default function Results() {
    const [password, setPassword] = useState("")
    const [result, setResults] = useState<any>()
    const [isResult, setIsResults] = useState(false)
    const [persons, setPersons] = useState<any>()


    const getResults = () => {
        axios.put("https://memes-site.ru/api/results", 
        {
            headers: {
            'Content-Type': 'application/json'
            },
            data: {
                "password": password,
            }
        }
        ).then((resp) => {
            if (resp.data !== undefined) {
                setResults(resp.data);
                setPersons(resp.data.persons.filter((p: any) => filterQuery(p, resp.data)))
                console.log(resp.data)
            }
        })
    }

    useEffect(()=>{
        if (result && persons) {
           if (result.persons[0] !== undefined && persons[0] !== undefined) {
            setIsResults(true)
        } 
        }
    }, [result, persons])

    const filterQuery  = (p: any, result: any) => {
        const meme = result.memeses.filter((m: any) => m.person_id === p.id && m.choosed)
        const isMeme = meme.length

        const hf = result.humorFirst.filter((h: any) => h.person_id === p.id)
        const isHf = hf.length

        const hs = result.humorSecond.filter((h: any) => h.person_id === p.id)
        const isHs = hs.length

        const lq = result.lastQuestion.filter((h: any) => h.person_id === p.id)
        const isLq = lq.length

        return isMeme !== 0 && isHf !== 0 && isHs !== 0 && isLq !== 0
    }

    return (
        <div>
            {!result && <div>
                <input 
                    placeholder="password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                />
                <button
                    onClick={getResults}
                >
                    submit
                </button>
                </div>
            }
            
            {isResult && <div>
                <table>
                    <thead>
                        <tr>
                            <td>N</td>
                            <td>Name</td>
                            <td>Age</td>
                            <td>Sex</td>
                            <td>Familiar</td>
                            <td>Group</td>
                            <td>memes</td>
                            <td>Tests result</td>
                            <td>Memes choosed reason</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        persons.map(
                            (p: any) => {
                                const meme = result.memeses.filter((m: any) => m.person_id === p.id && m.choosed)
                                meme.sort((a: any,b: any) => {return a.keyid-b.keyid})

                                const hf = result.humorFirst.filter((h: any) => h.person_id === p.id)
                                hf.sort((a: any,b: any) => {return a.keyid-b.keyid})

                                const hs = result.humorSecond.filter((h: any) => h.person_id === p.id)
                                hs.sort((a: any,b: any) => {return a.keyid-b.keyid})

                                const lq = result.lastQuestion.filter((h: any) => h.person_id === p.id)
                                if (p.age) return (
                                    <tr>
                                        <td>{p.id}</td>
                                        <td>{p.name}</td>
                                        <td>{p.age}</td>
                                        <td>{p.sex}</td>
                                        <td>{p.didseeit}</td>
                                        <td>{p.groupid}</td>
                                        <td>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <td>N</td>
                                                    <td>q1</td>
                                                    <td>q2</td>
                                                    <td>q3</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                            {
                                                meme.map(
                                                    (m: any) => {
                                                        return (
                                                            
                                                                <tr>
                                                                        <td>{m.keyid}</td>
                                                                        <td>{m.q1}</td>
                                                                        <td>{m.q2}</td>
                                                                        <td>{m.q3}</td>
                                                                </tr>
                                                                
                                                        )
                                                    }
                                                )
                                            }
                                            </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td>first test</td>
                                                    {
                                                        hf.map(
                                                            (h: any) => {
                                                                if (h.keyid === 22) {
                                                                    return (
                                                                        <td>{h.textanswer}</td>
                                                                    )
                                                                }
                                                                return (
                                                                    <td>{h.answer}</td>
                                                                )
                                                            }
                                                        )

                                                        }
                                                </tr>
                                                <tr>
                                                    <td>second test</td>                                               
                                                    {
                                                        hs.map(
                                                            (h: any) => {
                                                                return (
                                                                    <td>{h.answer}</td>  
                                                                )
                                                            }
                                                        )

                                                    }
                                                </tr>
                                                <tr>
                                                    <td>difference</td>                                               
                                                    {
                                                        hf.map(
                                                            (h: any, i: any) => {
                                                                return (
                                                                    <td>{hs[i] !== undefined ? h.answer - hs[i].answer : ""}</td>  
                                                                )
                                                            }
                                                        )

                                                    }
                                                </tr>

                                                <tr>
                                                    <td>mod. diff.</td>                                               
                                                    {
                                                        hf.map(
                                                            (h: any, i: any) => {
                                                                return (
                                                                    <td>{hs[i] !== undefined ? Math.abs(h.answer) - Math.abs(hs[i].answer) : ""}</td>  
                                                                )
                                                            }
                                                        )

                                                    }
                                                </tr>
                                            </table>
                                        </td>
                                        {
                                            lq.map(
                                                (m: any) => {
                                                    return (
                                                        <table>
                                                            <td>{m.howmanychoosed}</td>
                                                            <td>{m.why}</td>
                                                            <td>{m.aboutother}</td>
                                                            <td>{m.timeend}</td>
                                                        </table>
                                                    )
                                                }
                                            )
                                        }
                                        
                                    </tr>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
                
                </div>
            }
        </div>
    )
}
