import { observer } from "mobx-react-lite";
import "./question-card.css"
import Radio from "../../radio/radio";
import MainStore from "../../../store/main-store";

type questionCardProps = {
    image: any;
    keys: number;
    store: MainStore
}


const QuestionCard = observer( (props: questionCardProps) => {
    return (
        <div className="question-card" key={props.keys}>
            <img className="memes-image-q" alt="" src={props.image} />
            <div>
                <Radio
                    keys={props.keys}
                    text={"В какой мере этот мем кажется Вам смешным?"}
                    onEdit={props.store.setQuestionAnswer}
                    questionNumber={1}
                    value={props.store.memes[props.keys - 1].q1}
                    min={-5}
                    max={5}
                    />
                <Radio
                    keys={props.keys}
                    text={"Разместили бы Вы этот мем у себя на странице?"}
                    onEdit={props.store.setQuestionAnswer}
                    questionNumber={2}
                    value={props.store.memes[props.keys - 1].q2}
                    min={-5}
                    max={5}
                    />
                <Radio
                    keys={props.keys}
                    text={"В какой мере этот юмор сомнительный (например, может кого-то обидеть, вульгарен и т.п.)?"}
                    onEdit={props.store.setQuestionAnswer}
                    questionNumber={3}
                    value={props.store.memes[props.keys - 1].q3}
                    min={-5}
                    max={5}
                />
            </div>
            
        </div>
    )
})

export default QuestionCard