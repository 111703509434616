import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import HumorRadio from "../../components/radio/humor-radio";
import MainStore from "../../store/main-store";

import "./humor.css";

type propsHumorPage = {
    store: MainStore,
    kindOfGroup: string
}

type memesType = {
    key: number;
    image: string;
    isChoosed: boolean;
    q1: number;
    q2: number;
    q3: number;
}

const HumorPage = observer(({store, kindOfGroup}: propsHumorPage) => {
    const HumorStore = store.HumorStore

    const [group, setGroup] = useState("control")

    useEffect(()=>{
        if (window.sessionStorage.getItem('group') === "expert") {
            setGroup(window.sessionStorage.getItem('group')!)
        }
        const id = window.sessionStorage.getItem('person-id')
        const memes = JSON.parse(window.sessionStorage.getItem('memes')!)
        let memesChoosed = memes.filter((ob: memesType) => ob.isChoosed)
        for (let i=0; i<10; i++){
            axios.put("https://memes-site.ru/api/memes", 
            {
                headers: {
                'Content-Type': 'application/json'
                },
                data: {
                    "keyId": memesChoosed[i].key,
                    "person_id": id,
                    "q1": memesChoosed[i].q1,
                    "q2": memesChoosed[i].q2,
                    "q3": memesChoosed[i].q3
                }
            }
            ).then((resp) => {
                console.log(resp.data)
            }
        )
        }
    }, []
    )

    const sendAnswers = async () => {
        const id = window.sessionStorage.getItem('person-id')

        await axios.post("https://memes-site.ru/api/humor/new", 
            {
                headers: {
                'Content-Type': 'application/json'
                },
                data: {
                    "answers": HumorStore.answers,
                    "person_id": id
                }
            }
            ).then((resp) => {
                console.log("answers save resp", resp.data)
                document.location.href = "/final-questions"
            }
        )
    }
    return (
        <div>
            <p className="instruction">
                Оцените в какой мере высказывания описывают Вас по шкале от -5 (совсем не про меня) до 5 (абсолютно про меня)
            </p>

            {
               HumorStore.questions.map((question, i) => {
                return (
                    <HumorRadio
                        text={question}
                        questionNumber={i}
                        onEdit={HumorStore.addNewAnswer}
                    />
                )
               })
            }

            {group === "expert" && <div>
                <p className="instruction">
                    Спасибо. Прокомментируйте формулировки вопросов, пожалуйста. Все ли было понятно? Заметили ли какие-то противоречия? Удобно ли было отвечать? Плавные ли переходы между вопросами? Запомнились ли какие-то вопросы особо? Оставьте свой комментарий, пожалуйста, в свободной форме. 
                </p>

            <textarea className="textarea" onChange={(e)=> HumorStore.addTextAnswer(e.target.value)}>

                </textarea>
            </div>}

            <button className="submit-button" onClick={sendAnswers}>
                Далее
            </button>
        </div>
    )
})

export default HumorPage 