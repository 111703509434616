import { useEffect, useState } from "react"
import SuccessLogo from '../../assets/okay.png';
import "./error-popup.css"

export default function SuccesPopup () {

    const [text, setText] = useState("Отправка данных")

    useEffect(()=>
    {
        setTimeout(()=>{setText("Успешно отправлено!")}, 5000)
    })
   return (
    <div className="error-popup">
        <div>
            <p>{text}</p>
            {text==="Отправка данных" && <div className="loadingio-spinner-spinner-kn4vnvrxu">
                <div className="ldio-1lubqp3xnl2i">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                    </div>
                </div>
            }

            {
                text==="Успешно отправлено!" && <div className="error-message">
                    <img src={SuccessLogo} alt="" className="error-image"/>
                    <p>
                        Спасибо за участие!
                    </p>
                </div>
            }
        </div>    
    </div>
   ) 
}