import MemesCard from "../../components/first-step/simple-card/card";
import "./memes-choose.css";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import DidYouSeeIt from "../../components/did-you-see-it/did-you-see-it";
import MemesQuality from "../memes-quality/memes-quality";
import MainStore from "../../store/main-store";

type propsMemesChoosePage = {
    mainStore: MainStore
}

const MemesChoosePage = observer( ({mainStore}: propsMemesChoosePage) => {
    const [isMemesChooseed, setIsMemesChoosed] = useState(false);
    const [isMemesCanBeGradueted, setIisMemesCanBeGradueted] = useState(false);

    
    return (
        <div>
            {!isMemesChooseed && <p className="instruction"> Вам будет предъявлено 30 мемов. Выберите из них десять, которые Вы считаете наиболее смешными. На следующем этапе Вам нужно будет оценить их по нескольким параметрам (в какой мере они смешны, могут быть обидными и др.). Пересчитайте, пожалуйста, чтобы выбранных вами мемов было именно 10. </p>}
            {!isMemesChooseed && <div className="memes-choose-table">
                {
                    mainStore.getActualMemesList().map((memes)=>{
                        return (
                            <MemesCard store={mainStore} image={memes.image} onClick={()=>mainStore.setMemesChossed(memes.key - 1)} keys={memes.key}/> 
                        )
                    })
                }
                <div></div>
                <button onClick={()=>{
                    if (mainStore.getChoosedMemes().length === 10) {
                        mainStore.saveMemesToStorage() 
                        setIsMemesChoosed(true);
                    } else alert('Выбрано меньше 10 мемов')
                    }} className="submit-button">
                    Оценить выбранные мемы
                </button>
            </div>}

            {isMemesChooseed &&
                <>
                    {
                        !isMemesCanBeGradueted && <DidYouSeeIt onClick={setIisMemesCanBeGradueted}/>
                    }
                    {
                        isMemesCanBeGradueted && <MemesQuality store={mainStore}/>
                    }
                </>
            }
        </div>
    )
})

export default MemesChoosePage