import { observer } from "mobx-react-lite";
import "./card.css"
import MainStore from "../../../store/main-store";

type cardsProps = {
    image: any;
    onClick: Function;
    keys: number;
    store: MainStore
}

const MemesCard = observer( (props: cardsProps) => {
    const isChoosed = props.store.memes[props.keys - 1].isChoosed

    return (
        <div className="memes-card" onClick={()=>props.onClick()} key={props.keys}>
            <img className="memes-image" alt="" src={props.image} />
            <button
                id={`button${props.keys}`}
                onClick={()=>{
                    props.onClick()
                }}
                className={isChoosed? "submit-button choosedButton" : "submit-button"}
            >
                {isChoosed? "Взято" : "Беру!"}
            </button>
        </div>
    )
})

export default MemesCard