import { makeAutoObservable } from "mobx"
import IdentityStore from "./identity-store";
import HumorStore from "./humor-store";
import axios from "axios";

import m1 from "../assets/m1.jpg"
import m2 from "../assets/m2.jpg";
import m3 from "../assets/m3.jpg";
import m4 from "../assets/m4.jpg";
import m5 from "../assets/m5.jpg";
import m6 from "../assets/m6.jpg";
import m7 from "../assets/m7.jpg";
import m8 from "../assets/m8.jpg";
import m9 from "../assets/m9.jpg";
import m10 from "../assets/m10.jpg";
import m11 from "../assets/m11.jpg";
import m12 from "../assets/m12.jpg";
import m13 from "../assets/m13.jpg";
import m14 from "../assets/m14.jpg";
import m15 from "../assets/m15.jpg";
import m16 from "../assets/m16.jpg";
import m17 from "../assets/m17.jpg";
import m18 from "../assets/m18.jpg";
import m19 from "../assets/m19.jpg";
import m20 from "../assets/m20.jpg";
import m21 from "../assets/m21.jpg";
import m22 from "../assets/m22.jpg";
import m23 from "../assets/m23.jpg";
import m24 from "../assets/m24.jpg";
import m25 from "../assets/m25.jpg";
import m26 from "../assets/m26.jpg";
import m27 from "../assets/m27.jpg";
import m28 from "../assets/m28.png";

class MainStore {    
    memes = [
        {key: 1, image: m1, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 2, image: m2, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 3, image: m3, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 4, image: m4, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 5, image: m5, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 6, image: m6, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 7, image: m7, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 8, image: m8, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 9, image: m9, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 10, image: m10, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 11, image: m11, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 12, image: m12, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 13, image: m13, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 14, image: m14, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 15, image: m15, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 16, image: m16, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 17, image: m17, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 18, image: m18, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 19, image: m19, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 20, image: m20, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 21, image: m21, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 22, image: m22, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 23, image: m23, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 24, image: m24, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 25, image: m25, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 26, image: m26, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 27, image: m27, isChoosed: false, q1: 0, q2: 0, q3: 0},
        {key: 28, image: m28, isChoosed: false, q1: 0, q2: 0, q3: 0},
    ]
    IdentityStore: IdentityStore;
    HumorStore: HumorStore;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        this.IdentityStore = new IdentityStore();
        this.HumorStore = new HumorStore();
    }

    getActualMemesList() {
        return this.memes
    }

    getUnchoosedMemes() {
        const unchoosedMemes = this.memes.filter(ob => !ob.isChoosed)

        return unchoosedMemes
    }

    getChoosedMemes() {
        const choosedMemes = this.memes.filter(ob => ob.isChoosed)

        return choosedMemes
    }

    setMemesChossed(index: number) {
        if (this.getChoosedMemes().length < 10) {
            this.memes[index].isChoosed = true;
            axios.put("https://memes-site.ru/api/memes", 
            {
                headers: {
                'Content-Type': 'application/json'
                },
                data: {
                    "keyId": this.memes[index].key,
                    "person_id": window.sessionStorage.getItem("person-id"),
                    "q1": 0,
                    "q2": 0,
                    "q3": 0
                }
            }
            ).then((resp) => {
                console.log(resp.data)
            })
        } else alert("Уже выбрано 10 мемов")
    }

    setQuestionAnswer(index: number, question: number, value: number) {
        switch (question) {
            case 1: 
                this.memes[index].q1 = value;
                ////console.log(this.memes[index].q1)
                break;
            case 2: 
                this.memes[index].q2 = value;
                ////console.log(this.memes[index].q2)
                break;
            case 3: 
                this.memes[index].q3 = value;
                ////console.log(this.memes[index].q3)
                break;
        }
        this.saveMemesToStorage()
    }

    saveMemesToStorage() {
        window.sessionStorage.setItem('memes', JSON.stringify(this.memes))
        console.log("memes saved", JSON.parse(window.sessionStorage.getItem('memes')!))
    }
}

export default MainStore