import axios from "axios";
import { useState } from "react";
import "./did-you-see-it.css"

type propsDidYouSeeIt = {
    onClick: Function;
}

export default function DidYouSeeIt ({onClick}: propsDidYouSeeIt) {
    const [didSee, setDidSee] = useState(0)

    const sendAnswer = () => {
        const id = Number(window.sessionStorage.getItem('person-id')!)
        //console.log(id)
        //console.log(didSee)
        axios.put("https://memes-site.ru/api/person/upd",
        {
            headers: {
            'Content-Type': 'application/json'
            },
            data: {
                "person_id": id,
                "didSeeIt": didSee,
            }
        }
        ).then((resp) => {
            console.log(resp.data)
            onClick(true)
            }
        )
    }

    return (
        <div>
            <p className="instruction">
                Видели ли Вы раньше предложенные мемы? Если да, то сколько примерно из них?
            </p>
            <div className="radio-column">
                <label className="radio-label">
                    <input type="radio" name="see-it" onChange={() => setDidSee(0)}/>
                    ни одного до этого не видел
                </label>
                <label className="radio-label">
                    <input type="radio" name="see-it" onChange={() => setDidSee(1)}/>
                    видел парочку
                </label>
                <label className="radio-label">
                    <input type="radio" name="see-it" onChange={() => setDidSee(2)}/>
                    меньше половины знакомы
                </label>
                <label className="radio-label">
                    <input type="radio" name="see-it" onChange={() => setDidSee(3)}/>
                    больше половины знакомых
                </label>
                <label className="radio-label">
                    <input type="radio" name="see-it" onChange={() => setDidSee(4)}/>
                    почти все знакомы
                </label>
            </div>

            <button className="submit-button" onClick={sendAnswer}>
                Далее
            </button>
        </div>
    )
}