import { observer } from "mobx-react-lite"
import { useState } from "react"

import "./radio.css"

type radioProps = {
    text: string,
    questionNumber: number,
    onEdit: Function,
}

const HumorRadio = observer( (props: radioProps) => {
    const [value, setValue] = useState(0)
        
    return (
        <div>
            <p className="question">
                {props.text}
            </p>
            <p className="value">
                {value}
            </p>
            <div className="scale">
                <span>
                    <p>-5</p>
                </span>
                <div className="slidecontainer">
                    <input
                        onChange={(e) => {
                            setValue(Number(e.target.value))
                            props.onEdit(props.questionNumber, Number(e.target.value))
                            //console.log(e.target.value)
                        }}
                        type="range"
                        min="-5"
                        max="5"
                        value={value}
                        className="slider"
                        id="myRange"
                    />
                </div>

                <span>
                    <p>5</p>
                </span>
            </div>
            <div className="line"></div>
        </div>
    )
})

export default HumorRadio