import axios from "axios";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import SuccesPopup from "../../components/error-popup/success-popup";
import HumorRadio from "../../components/radio/humor-radio";
import MainStore from "../../store/main-store";

import "./humor.css";

type propsHumorPage = {
    store: MainStore
}

const HumorPageSeconChance = observer(({store}: propsHumorPage) => {
    const [error, setError] = useState(false);

    const HumorStore = store.HumorStore

    const sendAnswers = () => {
        const id = window.sessionStorage.getItem('person-id')
        //console.log(HumorStore.answers)

        axios.post("https://memes-site.ru/api/humor/second/new", 
            {
                headers: {
                'Content-Type': 'application/json'
                },
                data: {
                    "answers": HumorStore.answers,
                    "person_id": id
                }
            }
            ).then((resp) => {
                console.log(resp.data)
                setError(true)
            }
        )
    }

    return (
        <div>
            <p className="instruction-second">
            Кажется что-то пошло не так! Похоже часть данных не сохранилась. Пожалуйста постарайтесь вспомнить ваши ответы и внести их еще раз. 
            </p>

            {
               HumorStore.questions.map((question, i) => {
                return (
                    <HumorRadio
                        text={question}
                        questionNumber={i}
                        onEdit={HumorStore.addNewAnswer}
                    />
                )
               })
            }

            <button className="submit-button" onClick={sendAnswers}>
                 Отправить
            </button>

            {
                error && <SuccesPopup/>
            }
        </div>
    )
})

export default HumorPageSeconChance 