import {expertsGroup, controlGroup} from "./instruction"
import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import MainStore from "../../store/main-store"
import axios from "axios"

import "./start.css"

type StartProps = {
    groupType: string;
    store: MainStore;
    toloka?: boolean
}

const StartComponent = observer(({groupType, store, toloka}: StartProps) => {
    const [step, setStep] = useState(1)

    const IdentityStore = store.IdentityStore

    let instruction = expertsGroup.instruction
    let title = expertsGroup.title

    if (groupType === "control") {
            instruction = controlGroup.instruction
            title = controlGroup.title
        }

    const createNewPerson = ()  => {
        axios.post("https://memes-site.ru/api/person", 
        {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "age": IdentityStore.age,
                "name": toloka ? IdentityStore.name + "--toloka" : IdentityStore.name,
                "sex": IdentityStore.sex,
                "group": groupType === "control" ? "control" : "experiment"
            }
        }
        ).then((resp) => {
            console.log(resp.data.id)
            if (resp.data.id) {axios.post("https://memes-site.ru/api/memes", 
            {
                headers: {
                'Content-Type': 'application/json'
                },
                data: {
                    "person_id": resp.data.id,
                }
            }
            ).then((resp) => {
                console.log(resp.data)
            })}
            window.sessionStorage.setItem('person-id', resp.data.id)
        })
    }
    useEffect(() => { if (groupType === "") {
        IdentityStore.setKindOfGroupToExpert()
        window.sessionStorage.setItem('group', "expert");
    }}, [IdentityStore, groupType])
    
    return (
        <div>
            {step ===1 && <>
                <p className="title">
                    {title}
                </p>
                <p className="instruction">
                    {instruction}
                </p>
                <div className="column-div">
                    <input
                        className="column-input"
                        placeholder="Имя(никнейм)"
                        onChange={(e) => IdentityStore.setName(e.target.value)}
                    />
                    <p>Ваш пол:</p>
                    <select
                        id="sex"
                        name="sex"
                        className="column-input"
                        placeholder="female"
                        onChange={(e) => {
                            IdentityStore.setSex(e.target.value);
                            //console.log(IdentityStore.sex)
                        }}
                        >
                        <option value="female">женский</option>
                        <option value="male">мужской</option>
                    </select>
                    <input
                        className="column-input"
                        placeholder="Возраст"
                        type="number"
                        onChange={(e) => IdentityStore.setAge(Number(e.target.value))}
                    />
                    <label className="label">
                        Я даю согласие на обработку персональных данных в соответствии с 152-ФЗ "О персональных данных" и ознакомлен с условиями исследования
                    </label>
                    
                    <p onClick={()=>{
                            createNewPerson()
                            setStep(2)}
                        } className="submit-button">
                        Далее
                    </p>
                </div>
            </>}
            

            {step === 2 && <>
                {groupType === "" && <h2>
                    Процедура экспертизы
                </h2>}
                <p className="instruction">
                    Вам будет предъявлено 30 мемов. Выберите из них десять, которые Вы считаете наиболее смешными. На следующем этапе Вам нужно будет оценить их по нескольким параметрам (в какой мере они смешны, могут быть обидными и др.). Пересчитайте, пожалуйста, чтобы выбранных вами мемов было именно 10. 
                </p>
            
                <div className="column-div">
                    <a href="/choose-your-memes" className="submit-button">
                            Далее
                    </a>
                </div>
            </>}
        </div>
    )
})

export default StartComponent