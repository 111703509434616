import StartComponent from "../components/start/start";
import MainStore from "../store/main-store";

type propsSecondGroup = {
    store: MainStore
}

export default function SecondGroup ({store}: propsSecondGroup) {
    return (
        <div>
            <StartComponent groupType="control" store={store} toloka={false}/>
        </div>
    )
}