import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MainStore from './store/main-store';
import FirstGroup from './pages/group-one';
import SecondGroup from './pages/group-two';
import MemesChoosePage from './pages/memes-choose/memes-choose';
import HumorPage from './pages/humor/humor';
import { observer } from 'mobx-react-lite';
import FinalMemes from './pages/final-memes/final-memes';
import HumorPageSeconChance from './pages/humor/humor-secon-chance';
import Results from './pages/results/results';
import TolokaSecond from './pages/toloka-two';
import TolokaFirst from './pages/toloka-one';

const mainStore = new MainStore()

const App = observer(() => {
  const kindOfGroup = mainStore.IdentityStore.getKindOfGroup()
  //console.log("App" + kindOfGroup)
  const router = createBrowserRouter([
    {
      path: "/",
      element: <FirstGroup store={mainStore}/>,
    },
    {
      path: "/ght3fr5",
      element: <SecondGroup store={mainStore}/>
    },
    {
      path: "/choose-your-memes",
      element: <MemesChoosePage mainStore={mainStore}/>
    },
    {
      path:"/humor-in-my-life",
      element: <HumorPage store={mainStore} kindOfGroup={kindOfGroup}/>
    },
    {
      path:"/final-questions",
      element: <FinalMemes/>
    },
    {
      path:"/error-handler",
      element: <HumorPageSeconChance store={mainStore}/>
    },
    {
      path:"/results",
      element: <Results />
    },
    {
      path: "/toloka/ght3fr5",
      element: <TolokaSecond store={mainStore}/>
    },
    {
      path: "/toloka",
      element: <TolokaFirst store={mainStore}/>
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
})

export default App;
