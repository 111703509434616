import { useEffect, useState } from "react"
import ErrorLogo from '../../assets/fail.png';
import SuccessLogo from '../../assets/okay.png';
import "./error-popup.css"

export default function ErrorPopup () {

    const [text, setText] = useState("Отправка данных")

    useEffect(()=>
    {
        setTimeout(()=>{setText("Ошибка в данных опросника")}, 5000)
    })
   return (
    <div className="error-popup">
        <div>
            <p>{text}</p>
            {text==="Отправка данных" && <div className="loadingio-spinner-spinner-kn4vnvrxu">
                <div className="ldio-1lubqp3xnl2i">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                    </div>
                </div>
            }

            {
                text==="Ошибка в данных опросника" && <div className="error-message">
                    <div className="row">
                        <p>Выбранные мемы: Отправлено!</p>
                            <p> </p>
                            <img src={SuccessLogo} alt="" className="mini-image"/>
                    </div>
                    <div className="row">
                        <p>Оценка мемов: Отправлено!</p>
                            <p> </p>
                            <img src={SuccessLogo} alt="" className="mini-image"/>
                    </div>
                    <div className="row">
                        <p>Данные опросника: Ошибка!</p>
                            <p> </p>
                            <img src={ErrorLogo} alt="" className="mini-image"/>
                    </div>
                    <a href="/error-handler" className="submit-button">
                        Заполнить заново
                    </a>
                </div>
            }
        </div>    
    </div>
   ) 
}