import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import ErrorPopup from "../../components/error-popup/error-popup";
import Radio from "../../components/radio/radio";
import "./final-memes.css"

type Memes = {
    key: number;
        image: string;
        isChoosed: boolean;
        q1: number;
        q2: number;
        q3: number;
}

const FinalMemes = observer(() => {
   // const [memes, setMemes] = useState<Memes[]>();
    const [why, setWhy] = useState("");
    const [id, setId] = useState(0);
    const [other, setOther] = useState("");
    const [isInstruction, setIsInstruction] = useState(true);
    const [unchoosedMemes, setUnchoosedMemes] = useState<Memes[]>();
    const [howManyMemes, setHowManyMemes] = useState(0);
    const [error, setError] = useState(false);

    const findTenMemes = () => {
        const memes = JSON.parse(window.sessionStorage.getItem('memes')!)
        let unMemes = [] as Memes[]
        memes?.map((memes: Memes) => {
            if (memes.isChoosed === false) {
                if (unMemes.length < 10) unMemes.push(memes)
            }})
        setUnchoosedMemes(unMemes)
        //console.log(unMemes)
    }
    
    const setter = (keys: number, questionNumber: number, value: number) => {
        setHowManyMemes(value)
    }

    const saveAnswers = () => {
        const date = Date()
        axios.post( "https://memes-site.ru/api/last",
        {
            headers: {
            'Content-Type': 'application/json'
            },
            data: {
                "person_id": id,
                "howManyChoosed": howManyMemes,
                "why": why,
                "aboutOther": other,
                "timestamp": date.toString()
            }
        }
        ).then((resp) => {
            console.log(resp.data)
            setError(true)
            }
        )
    }

    return (
        <>
        { isInstruction && <div className="instruction-box">
            <p className="instruction">
                Благодаря вашим ответам и ответам других участников были отобраны следующие 10 самых смешных мемов. Отметьте сколько из них вошли в ТОП-10 по результатам вашей оценки. Мы хотели бы лучше понять, почему они для вас смешны. Поясните, по какому принципу Вы их выбирали. Еще раз спасибо!
            </p>
            <button className="submit-button"  onClick={()=>{
                // setMemes(JSON.parse(window.sessionStorage.getItem('memes')!));
                // //console.log(memes)
                setId(Number(window.sessionStorage.getItem('person-id')!));
                findTenMemes();
                setIsInstruction(false)
                }}>
                Далее
            </button>
        </div>
        }
        { !isInstruction &&
            <div>
                <p className="instruction">
                    Благодаря вашим ответам и ответам других участников были отобраны следующие 10 самых смешных мемов. Отметьте сколько из них вошли в ТОП-10 по результатам вашей оценки. Мы хотели бы лучше понять, почему они для вас смешны. Поясните, по какому принципу Вы их выбирали. Еще раз спасибо!
                </p>
                <div className="memes-choose-table-final">
                    {
                        unchoosedMemes?.map((memes, i) => {
                            if (!memes.isChoosed) {
                                return (
                                    <div className="memes-card-final" key={i}>
                                        <img className="memes-image-final" alt="" src={memes.image} />
                                    </div>
                                )
                            }
                            return <></>
                        })
                    }
                </div>
                <Radio
                    text={"Сколько мемов из тех, которые Вы выбрали, попали в рейтинговый список (ТОП-10)?"}
                    keys={0}
                    questionNumber={0}
                    value={howManyMemes}
                    onEdit={setter}
                    min={0}
                    max={10}
                />

                <div>
                    <p className="semi-instruction">
                        Почему они показались вам особенно смешными? 
                    </p>
                    <textarea className="textarea" onChange={(e) => {setWhy(e.target.value)}}>
                    </textarea>
                </div>

                <div>
                    <p className="semi-instruction">
                        Как вам остальные мемы из списка?  
                    </p>
                    <textarea className="textarea" onChange={(e) => {setOther(e.target.value)}}>
                    </textarea>
                </div>

                <p className="instruction2">Спасибо за ваш вклад в исследование!</p>
                <button className="submit-button"  onClick={saveAnswers}>
                    Отправить
                </button>

                {
                    error && <ErrorPopup/>
                }
            </div>
        }
        </>
    )
})

export default FinalMemes