import { makeAutoObservable } from "mobx"

class IdentityStore {
    kindOfGroup
    name = ""
    sex = "female"
    age = 0

    constructor () {
        this.kindOfGroup = "control"
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setName (name: string) {
        this.name = name
    }

    setSex (sex: string) {
        this.sex = sex
    }

    setAge (age: number) {
        this.age = age
    }

    setKindOfGroupToExpert() {
        this.kindOfGroup = "expert";
    }

    getKindOfGroup() {
        return this.kindOfGroup
    }
}

export default IdentityStore